$(document).ready(function () {
  /**
   * 导航动效
   */
  var animatedHeader = (function () {
    var docElem = document.documentElement,
      header = $('#header'),
      didScroll = false;

    scrollPage();
    window.addEventListener('scroll', function (event) {
      if (!didScroll) {
        didScroll = true;
        setTimeout(scrollPage, 250);
      }
    }, false);

    function scrollPage() {
      var scrollY = window.pageYOffset || docElem.scrollTop;
      if (scrollY >= 150) {
        header.removeClass('expand')
      } else {
        header.addClass('expand')
      }
      didScroll = false;
    }
  })();

  /**
   * 多级下拉菜单
   */
  $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }

    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');

    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
      $('.dropdown-submenu .show').removeClass("show");
    });

    return false;
  });

  /**
   * 百度地图
   */
  var mapBox = $('#map-box');
  if (mapBox.length > 0) {
    var mapInfo = $.extend({
      lng: 87.586399, // 经度
      lat: 43.875055, // 纬度
      title: '新疆百疆图网络服务有限公司', // 公司名称
      message: '地址：乌市新市区长沙路158号绿苑雅筑2号楼4楼' // 地址
    }, window.MAP_INFO);

    // 百度地图API功能
    var map = new BMap.Map("map-box");
    var point = new BMap.Point(mapInfo.lng, mapInfo.lat);
    var marker = new BMap.Marker(point); // 创建标注
    map.addOverlay(marker); // 将标注添加到地图中
    map.centerAndZoom(point, 15);

    var opts = {
      width: 200, // 信息窗口宽度
      height: 100, // 信息窗口高度
      title: mapInfo.title, // 信息窗口标题
      enableMessage: true, //设置允许信息窗发送短息
      message: mapInfo.message
    }
    var infoWindow = new BMap.InfoWindow(mapInfo.message, opts); // 创建信息窗口对象 
    // map.openInfoWindow(infoWindow, point); //开启信息窗口
    marker.addEventListener("click", function () {
      map.openInfoWindow(infoWindow, point); //开启信息窗口
    });

    var top_left_navigation = new BMap.NavigationControl(); //左上角，添加默认缩放平移控件

    map.addControl(top_left_navigation);
  }

  /**
   * 返回顶部
   */
  var page = $(document);
  var scrollTotop = $('.scroll-to-top');
  if (scrollTotop.length > 0) {
    if (page.scrollTop() > 300) {
      scrollTotop.fadeIn();
    }
    page.on('scroll', function () {
      if (page.scrollTop() > 300) {
        scrollTotop.fadeIn();
      } else {
        scrollTotop.fadeOut();
      }
    });
    scrollTotop.click(function (e) {
      e.preventDefault();
      $('html, body').animate({
        scrollTop: 0
      }, 500);
      return false;
    });
  }
});

var mySwiper = new Swiper('.common_banner', {
  loop: true, // 循环模式选项
  autoplay: {
    disableOnInteraction: false,
    delay: 5000
  },
  speed: 2000
})


// 定义一些公共方法和对象
var _mm = {
  // 移除指定样式
  removeClass: function (_domNode, _className) {
    let classNames = _domNode.className.trim().split(' ');
    if (classNames.indexOf(_className) > -1) {
      classNames.splice(classNames.indexOf(_className), 1);
      let newClassNames = classNames.join(' ').trim();
      _domNode.className = newClassNames;
    }
  },
  // 添加指定样式
  addClass: function (_domNode, _className) {
    let classNames = _domNode.className.trim().split(' ');
    if (classNames.indexOf(_className) < 0) {
      classNames.push(_className);
      let newClassNames = classNames.join(' ').trim();
      _domNode.className = newClassNames;
    }
  },
  // 触发“switch_method”事件之后切换内容的方法
  switch_kid: function (switch_method,
    wrap_id,
    leave_detect,
    btn_dom_name,
    cont_dom_name,
    btn_switch_class,
    cont_switch_class) {
    let switch_btn = document.getElementsByName(btn_dom_name);
    let switch_cont = document.getElementsByName(cont_dom_name);
    for (let i = 0; i < switch_btn.length; i++) {
      switch_btn[i].index = i;
      switch_btn[i][switch_method] = function () {
        for (let j = 0; j < switch_btn.length; j++) {
          _mm.removeClass(switch_btn[j], btn_switch_class);
          _mm.removeClass(switch_cont[j], cont_switch_class);
        }
        _mm.addClass(switch_btn[i], btn_switch_class);
        _mm.addClass(switch_cont[i], cont_switch_class);
      }
      // 如果有鼠标离开之后撤销之前添加的激活样式的需求则设定“leave_detect”变量为true
      if (leave_detect) {
        document.getElementById(wrap_id).onmouseleave = function () {
          for (let j = 0; j < switch_btn.length; j++) {
            _mm.removeClass(switch_btn[j], btn_switch_class);
            _mm.removeClass(switch_cont[j], cont_switch_class);
          }
        }
      }
    }
  },
  // 平滑滚动方法,传入"目标位置"和"需要滚动的DOM对象"
  smoothScroll: function smoothScroll(aimPosition, passenger) {
    var currentPosition = passenger.scrollTop;
    var step = Math.abs(currentPosition - aimPosition) / 50;
    if (aimPosition !== currentPosition) {
      if (aimPosition > currentPosition) {
        smoothDown();
      } else {
        smoothUp();
      }
    }
    function smoothDown() {
      if (currentPosition < aimPosition) {
        // currentPosition每次朝着aimPosition前进step像素
        currentPosition += step;
        passenger.scrollTop = currentPosition;
        // (异步回调)将每一次回调在间隔时间结束后都依次推入事件队列一个接一个执行
        setTimeout(smoothDown, 10);
        // 普通的回调
        // smoothDown()
      } else {
        passenger.scrollTop = aimPosition;
      }
    }
    function smoothUp() {
      if (currentPosition > aimPosition) {
        currentPosition -= step;
        passenger.scrollTop = currentPosition;
        setTimeout(smoothUp, 10);
      } else {
        passenger.scrollTop = aimPosition;
      }
    }
  },
  getUserAgent: function (IElimit_version) {
    // 获取客户端类型
    let userAgent = navigator.userAgent;
    console.log(userAgent);
    let isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1;
    let isEdge = userAgent.indexOf("Edge") > -1 && !isIE;
    let isIE11 = userAgent.indexOf("Trident") > -1 && userAgent.indexOf("rv:11.0") > -1;
    if (isIE) {
      // 得到正则表达式：/MSIE (\d+\.\d+);/ 正则表达式中加括号可以直接获得匹配的字符串
      let reIE = new RegExp("MSIE (\\d+\\.\\d+);");
      let ieVersion = userAgent.match(reIE)[1].split('.')[0];
      console.log("当前的浏览器是 IE" + ieVersion);
      if (ieVersion < IElimit_version) {
        _mm.addClass(document.getElementById("golbal_tips"), 'show_tips');
      }
    } else if (isIE11) {
      console.log("当前的浏览器是 IE11");
    } else if (isEdge) {
      console.log("当前的浏览器是Edge浏览器");
    } else {
      console.log("这是非IE浏览器");
    }
  },

  // 事件委托封装方法
  //参数1：父元素ID
  //参数2：子元素标签名称
  //参数3：事件委托触发的事件类型
  //参数4：执行的方法函数
  eventDelegate: function (parentSelector, targetSelector, events, foo) {
    // 触发执行的函数
    function triFunction (e) {
      // 兼容性处理
      var event = e || window.event;
      // 获取到目标阶段指向的元素
      var target = event.target || event.srcElement;
      // 获取到代理事件的函数
      var currentTarget = event.currentTarget;
      // 处理 matches 的兼容性
      if (!Element.prototype.matches) {
        Element.prototype.matches =
          Element.prototype.matchesSelector ||
          Element.prototype.mozMatchesSelector ||
          Element.prototype.msMatchesSelector ||
          Element.prototype.oMatchesSelector ||
          Element.prototype.webkitMatchesSelector ||
          function(s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
              i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) {}
            return i > -1;            
          };
      }
      // 遍历外层并且匹配
      while (target !== currentTarget) {
        // 判断是否匹配到我们所需要的元素上
        if (target.matches(targetSelector)) {
          var sTarget = target;
          // 执行绑定的函数，注意 this
          foo.call(sTarget, Array.prototype.slice.call(arguments))
        }
        target = target.parentNode;
      }
    }
    // 如果有多个事件的话需要全部一一绑定事件
    events.split('.').forEach(function (evt) {
      // 多个父层元素的话也需要一一绑定
      Array.prototype.slice.call(document.querySelectorAll(parentSelector)).forEach(function ($p) {
        $p.addEventListener(evt, triFunction);
      });
    });
  }
};

_mm.switch_kid('onmouseover', '', false, 'switch_type_btn', 'switch_type_cont', 'activated', 'activated');

